import { useCallback, useState, useContext } from 'react';
import { VentasAdd, VentasEspecializadosAdd, ModeloAdd, ModeloEspecializadosAdd, VendedorAdd, VendedorEspecializadosAdd, DistribuidorAdd, DistribuidorEspecializadosAdd } from '../services/dashboard';
import Context from 'context/UserContext'

export default function useCompras() {
    const [Ventas, setVentas] = useState([]);
    const [VentasEsp, setVentasEsp] = useState([]);
    const [Modelos, setModelos] = useState([]);
    const [ModelosEsp, setModelosEsp] = useState([]);
    const [Vendedor, setVendedor] = useState([]);
    const [VendedorEsp, setVendedorEsp] = useState([]);
    const [Distribuidor, setDistribuidor] = useState([]);
    const [DistribuidorEsp, setDistribuidorEsp] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });
    const { setPuntos } = useContext(Context)

    const VentasAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        VentasAdd(
            data, token
        ).then(response => {
            setState({
                loading: false,
                error: false,
                message: 'Busqueda realizada con exito',
                success: true
            })
            setVentas(response);
        })
    }, [])

    const VentasEspecializadosAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        VentasEspecializadosAdd(
            data, token
        ).then(response => {
            setState({
                loading: false,
                error: false,
                message: 'Busqueda realizada con exito',
                success: true
            })
            setVentasEsp(response);
        })
    }, [])

    const ModeloAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        ModeloAdd(
            data, token
        ).then(response => {
            setState({
                loading: false,
                error: false,
                message: 'Busqueda realizada con exito',
                success: true
            })
            setModelos(response);
        })
    }, [])

    const ModeloEspecializadosAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        ModeloEspecializadosAdd(
            data, token
        ).then(response => {
            setState({
                loading: false,
                error: false,
                message: 'Busqueda realizada con exito',
                success: true
            })
            setModelosEsp(response);
        })
    }, [])

    const VendedorAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        VendedorAdd(
            data, token
        ).then(response => {
            setState({
                loading: false,
                error: false,
                message: 'Busqueda realizada con exito',
                success: true
            })
            setVendedor(response);
        })
    }, [])

    const VendedorEspecializadosAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        VendedorEspecializadosAdd(
            data, token
        ).then(response => {
            setState({
                loading: false,
                error: false,
                message: 'Busqueda realizada con exito',
                success: true
            })
            setVendedorEsp(response);
        })
    }, [])

    const DistribuidorAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        DistribuidorAdd(
            data, token
        ).then(response => {
            setState({
                loading: false,
                error: false,
                message: 'Busqueda realizada con exito',
                success: true
            })
            setDistribuidor(response);
        })
    }, [])

    const DistribuidorEspecializadosAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        DistribuidorEspecializadosAdd(
            data, token
        ).then(response => {
            setState({
                loading: false,
                error: false,
                message: 'Busqueda realizada con exito',
                success: true
            })
            setDistribuidorEsp(response);
        })
    }, [])


    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Ventas,
        VentasEsp,
        Modelos,
        ModelosEsp,
        Vendedor,
        VendedorEsp,
        Distribuidor,
        DistribuidorEsp,
        VentasAddService,
        VentasEspecializadosAddService,
        ModeloAddService,
        ModeloEspecializadosAddService,
        VendedorAddService,
        VendedorEspecializadosAddService,
        DistribuidorAddService,
        DistribuidorEspecializadosAddService
    }
}