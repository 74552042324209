import React, { useState, useEffect } from "react";
import useRanking from "../../hooks/useRanking";
import useUser from "../../hooks/useUser";
import toast, { Toaster } from "react-hot-toast";
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { filterBy } from "@progress/kendo-data-query";
import "@progress/kendo-theme-default/dist/all.css";

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  {
    field: "folio",
    dir: "desc",
  },
];

const PageTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </div>
  );
};

// obtener mes actual
const mesActual = () => {
  let meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  let d = new Date();
  let m = d.getMonth();
  return meses[m];
};

export default function Ventas() {
  const {
    success,
    message,
    error,
    LugarRegion,
    GanadorRegionGetService,
  } = useRanking();
  const { tokenFull, IdUser, Roles, Dealer } = useUser();
  const [Desde, setDesde] = useState("");
  const [Hasta, setHasta] = useState("");
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  const ENDPOINT = process.env.REACT_APP_BASE_URL;

  const pageChange = (event) => {
    setPage(event.page);
  };

  const _export = React.useRef(null);

  const _pdfExport = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const exportPDF = () => {
    if (_pdfExport.current !== null) {
      _pdfExport.current.save();
    }
  };

  useEffect(() => {
    GanadorRegionGetService(tokenFull, 'MX02');
  }, [GanadorRegionGetService]);

  useEffect(() => {
    if (success) {
      toast.dismiss();
      toast.success(message);
    }
  }, [message, success]);

  return (
    <>
      <Toaster />
      <h2 className="titulo2">Lista de ganadores región Centro</h2>
      <br />
      <div className="row">
        <div className="col-md-12">
          <Grid
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            filterable={true}
            filter={filter}
            onFilterChange={(e) => setFilter(e.filter)}
            pageable={true}
            data={filterBy(
              LugarRegion.slice(page.skip, page.take + page.skip),
              filter
            )}
            skip={page.skip}
            take={page.take}
            total={LugarRegion.length}
            onPageChange={pageChange}
            style={{
              height: "620px",
            }}
          >
            <GridNoRecords>No hay informacion disponible</GridNoRecords>
            <GridColumn
              field="Lugar"
              filter="text"
              format={null}
              cell={(e) => {
                let lugar = e.dataIndex + 1;
                return <td style={lugar < 3 ? {marginTop: 10} : {marginTop: 10}}>{lugar}</td>;
              }}
            />
            <GridColumn
              field="Vendedor"
              filter="text"
              format={null}
              cell={(e) => {
                let nombre =
                  e.dataItem.nombre +
                  " " +
                  e.dataItem.apellido_paterno +
                  " " +
                  e.dataItem.apellido_materno;
                  let lugar = e.dataIndex + 1;
                return <td style={lugar < 3 ? {marginTop: 10} : {marginTop: 10}}>{nombre}</td>;
              }}
            />
            <GridColumn
              field="Puntos"
              filter="text"
              format={null}
              cell={(e) => {
                let total = e.dataItem.puntos;
                let lugar = e.dataIndex + 1;
                return (
                  <td style={lugar < 3 ? {marginTop: 10,  textAlign: "right"} : {marginTop: 10, textAlign: "right"}}>{total}</td>
                );
              }}
            />
            {Roles == "Admin" ? (
            <GridColumn
              field="Monto"
              filter="text"
              format={null}
              cell={(e) => {
                let lugar = e.dataIndex + 1;
                let monto = 0;
                if (lugar >= 1 && lugar <= 2) {
                  monto = 500;
                }
                let lugarr = e.dataIndex + 1;
                return (
                  <td style={lugarr < 3 ? {marginTop: 10,  textAlign: "right"} : {marginTop: 10, textAlign: "right"}}>
                    {"$" + formatNumber(monto)}
                  </td>
                );
              }}
            />
            ) : (
              ""
            )}
          </Grid>
        </div>
      </div>
    </>
  );
}
