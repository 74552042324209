import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import useUser from '../../hooks/useUser';
import usePremios from '../../hooks/usePremios';
import Select from 'react-select';

export default function ModalAdd({
    modal,
    toggle,
    Nombre,
    Categoria,
    Precio,
    Descripcion,
    Link,
    Update,
    Id,
    setCategoria,
    setNombre,
    setPrecio,
    setDescripcion,
    setLink,
    PremiosGetService }) {

    const { Categorias, CategoriasGetService, PremiosAddService, PremiosUpdateService, success, error, message } = usePremios();
    const { tokenFull, IdUser, Puntos } = useUser();
    const [selectedFile, setselectedFile] = useState('')
    const [isFilePicked, setisFilePicked] = useState(false)
    const [SelectedCategoria, setSelectedCategoria] = useState('');
    const [Imagen1, setImagen1] = useState('');
    const [Imagen2, setImagen2] = useState('');

    useEffect(() => {
        if (success == true) {
            PremiosGetService(tokenFull)
        }
    }, [PremiosGetService, success])

    useEffect(() => {
        if (error){

        }
    }, [message, error])

    useEffect(() => {
        if (success){

        } 
    }, [message, success])

    useEffect(() => {
        CategoriasGetService(tokenFull)
    }, [CategoriasGetService])

    const premiosAgregados = () => {
        if (Update) {

            const data = new FormData();
            data.append("id", Id);
            data.append("categoria", Categoria);
            data.append("product", Nombre);
            data.append("price", Precio);
            data.append("description", Descripcion);
            data.append("link", Link);
            data.append("imagen", Imagen1);
            data.append("imagen_second", Imagen2);

            PremiosUpdateService(data, tokenFull);
            toggle();
        } else {
            const data = new FormData();
            data.append("categoria", Categoria);
            data.append("product", Nombre);
            data.append("price", Precio);
            data.append("description", Descripcion);
            data.append("link", Link);
            data.append("imagen", Imagen1);
            data.append("imagen_second", Imagen2);

            PremiosAddService(data, tokenFull);
            toggle();
        }
    }

    const changeHandler = (event) => {
        setselectedFile(event.target.files[0]);
        setisFilePicked(true);
    };


    return <>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Agregar Premio</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12">
                        <Select
                            value={SelectedCategoria}
                            onChange={e => {
                                setCategoria(e.value)
                                setSelectedCategoria(e)
                            }}
                            options={Categorias}
                            placeholder={Categoria ? Categoria : "Categoria"}
                        />
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-12">
                        <InputGroup>
                            <Input placeholder="nombre" value={Nombre} onChange={e => setNombre(e.target.value)} />
                        </InputGroup>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <InputGroup>
                            <Input placeholder="Precio" value={Precio} onChange={e => setPrecio(e.target.value)} />
                        </InputGroup>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <InputGroup>
                            <Input placeholder="Descripcion" value={Descripcion} onChange={e => setDescripcion(e.target.value)} />
                        </InputGroup>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <InputGroup>
                            <Input placeholder="Keywords" value={Link} onChange={e => setLink(e.target.value)} />
                        </InputGroup>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <InputGroup>
                            <Input placeholder="Imagen 1" value={Imagen1} onChange={e => setImagen1(e.target.value)} />
                        </InputGroup>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <InputGroup>
                            <Input placeholder="Imagen 2" value={Imagen2} onChange={e => setImagen2(e.target.value)} />
                        </InputGroup>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-12">
                        <input onChange={changeHandler} type={'file'} />
                    </div>
                </div> */}
            </ModalBody>
            <ModalFooter>
                <Button className="backend_boton" onClick={(() => premiosAgregados())}>Agregar</Button>{' '}
            </ModalFooter>
        </Modal>
    </>
}
