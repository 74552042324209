import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import useUser from '../../hooks/useUser';
import useProducts from '../../hooks/useProducts';

export default function ModalFactura({
    modal,
    toggle,
    Id,
    Imagen, }) {

    return <>
        <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Comprobante de depósito</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12">
                        <img src={Imagen} className="img-fluid" />
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </>
}
