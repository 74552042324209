const ENDPOINT = process.env.REACT_APP_API_URL;

// trarer registros
export async function RankingGet( token, Roles ) {
    return fetch(`${ENDPOINT}/rankingGet/${Roles}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

// traer secciones
export async function SeccionGet( token, Dealer ) {
  return fetch(`${ENDPOINT}/seccionGet/${Dealer}`, {
      headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': token
      },
      method: 'get'
  })
      .then(res => res.json()).then(response => {
          return response;
      });
}

export async function RegionGet( token, Dealer ) {
    return fetch(`${ENDPOINT}/regionGet/${Dealer}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
  }

export async function SeccionesGet( token, Seccion ) {
  return fetch(`${ENDPOINT}/seccionesGet/${Seccion}`, {
      headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': token
      },
      method: 'get'
  })
      .then(res => res.json()).then(response => {
          return response;
      });
}

export async function GanadorRegionGet( token, Seccion ) {
  return fetch(`${ENDPOINT}/ganadorRegionGet/${Seccion}`, {
      headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': token
      },
      method: 'get'
  })
      .then(res => res.json()).then(response => {
          return response;
      });
}


// trarer registros
export async function RankingAdd(data, token) {
    return fetch(`${ENDPOINT}/rankingAdd`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: token,
      },
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      });
  }


  export async function RankingAddCampana(data, token) {
    return fetch(`${ENDPOINT}/rankingAddCampana`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: token,
      },
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      });
  }

  export async function RankingCampanaGet(token) {
    return fetch(`${ENDPOINT}/rankingCampanaGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}