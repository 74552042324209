import React, { useEffect, useContext} from "react";
import { useForm } from "react-hook-form";
import { Container, Form, Row, Col, Badge, Button } from "react-bootstrap";
import api from "services/equivalences";
import Context from "context/UserContext";

export default function EquivalenceEditar({ id, puntos, modelo, show, get_equivalences }) {
  const { token } = useContext(Context);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm();

  useEffect(() => {
    setValue('points', puntos);
  }, [])
  
  const onSubmit = ({ points }) => {
     api.editEquivalence(token, id, points)
     .then((response) => {
      if (response.hasOwnProperty("errors")) {        
        alert("Favor de revisar el formulario.");
      } else {        
        show(false);
        get_equivalences();
      }
    }); 
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Form.Group className="mb-3" controlId="FormPuntos">
          <h2>
            <Badge bg="secondary">{modelo}</Badge>
          </h2>

          <Form.Label>Puntos</Form.Label>
          <Form.Control
            type="text"
            placeholder="puntos"
            {...register("points", {
              required: "El campo es obligatorio.",
              min: {
                value: 1,
                message:"El número debe ser entero positivo.",
              },
              pattern: {
                value: /^[0-9]+$/,                 
                message: "Debe escribir un número.",
              },

            })}
          />
          <Form.Text className="text-muted">{errors.points?.message}</Form.Text>
        </Form.Group>
        <Row>
          <Col>
            <Button type="submit" className="mb-2">
              Actualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
