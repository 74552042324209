import React, { useMemo, useState } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, getSortedRowModel, getFilteredRowModel, flexRender } from '@tanstack/react-table';
import { Button } from 'reactstrap';

const DynamicTable = ({ columns, data, view, handleUpdateStatus, handleViewFactura, pageSizeOptions = [5, 10, 20, 30] }) => {
    const [sorting, setSorting] = useState([]);
    const [globalFilter, setGlobalFilter] = useState([]);
    const [monthFilter, setMonthFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');

    const filteredData = useMemo(() => {
        let filtered = data;
        if(monthFilter){
            filtered = filtered.filter(row=>{      
                const date = new Date(row.fecha_venta);
                const month = date.getMonth() + 1; // getMonth() devuelve un valor entre 0-11, así que sumamos 1
                return month === parseInt(monthFilter);
            })
        }

        if(statusFilter){
            filtered = filtered.filter(row => row.status === statusFilter);
        }

        return filtered;
    }, [data, monthFilter, statusFilter]);

  const {
    getHeaderGroups,
    getRowModel,
    setPageSize,
    setPageIndex,
    previousPage,
    getCanPreviousPage,
    getPageCount,
    nextPage,
    getCanNextPage,
    getState,
  } = useReactTable({
    data: filteredData,
    columns,
    initialState: {
        pagination:{
            pageSize: 5
        }
    },
    state:{
        sorting,
        globalFilter
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const getStatusClass = (status) =>{
    if(view==='accesorios'){
        switch(status){
            case 'validado': 
                return 'badge bg-success';
            case 'en espera': 
                return 'badge bg-warning';
            case 'no validado': 
                return 'badge bg-danger';
            default: 
                return '';
        }
    }
    return '';
  }

  return (
    <div className='flex flex-col min-h-screen max-w-4x1 mx-auto py-12 px-4 sm:px-6 lg:px-8' style={{ padding: '3rem 1rem' }}>
    {/* <div className='flex flex-col min-h-screen max-w-4x1 mx-auto py-12 px-4 sm:px-6 lg:px-8'> */}
        {/* <label>Filtrar por mes:</label> */}
        <div style={{ overflowX: 'auto', backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
        <div className='d-flex justify-content-between'>
                <label>Filtrar por mes:</label>
                <select
                    id="monthFilter"
                    value={monthFilter}
                    onChange={(e)=>setMonthFilter(e.target.value)}
                    style={{
                        width: '25%', 
                        paddingLeft: '2.5rem',   // pl-10 -> 2.5rem
                        paddingRight: '1rem',    // pr-4 -> 1rem
                        paddingTop: '0.5rem',    // py-2 -> 0.5rem
                        paddingBottom: '0.5rem', // py-2 -> 0.5rem
                        border: '1px solid #D1D5DB', // border-gray-300 -> #D1D5DB
                        borderRadius: '0.375rem',  // rounded-md -> 0.375rem
                        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)', // shadow-sm
                        outline: 'none',  // Asegura que no aparezca borde al enfocarse
                        transition: 'border-color 0.2s ease, box-shadow 0.2s ease', // Añade transición
                    }}
                    onFocus={(e) => {
                        e.target.style.borderColor = '#fd7e14'; // focus:border-indigo-500 -> #6366F1
                        e.target.style.boxShadow = '0 0 0 2px rgba(251, 107, 4, 0.4)'; // focus:ring-indigo-500
                    }}
                    onBlur={(e) => {
                        e.target.style.borderColor = '#D1D5DB'; // Regresa al color original
                        e.target.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.1)'; // Regresa a la sombra original
                    }}
                >
                    <option value="">Todos</option>
                    <option value="1">Enero</option>
                    <option value="2">Febrero</option>
                    <option value="3">Marzo</option>
                    <option value="4">Abril</option>
                    <option value="5">Mayo</option>
                    <option value="6">Junio</option>
                    <option value="7">Julio</option>
                    <option value="8">Agosto</option>
                    <option value="9">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                </select>
                <label htmlFor="statusFilter" style={{ marginRight: '1rem' }}>Filtrar por estatus:</label>
                <select
                        id="statusFilter"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        style={{
                            width: '20%', 
                            paddingLeft: '2.5rem',   // pl-10 -> 2.5rem
                            paddingRight: '1rem',    // pr-4 -> 1rem
                            paddingTop: '0.5rem',    // py-2 -> 0.5rem
                            paddingBottom: '0.5rem', // py-2 -> 0.5rem
                            border: '1px solid #D1D5DB', // border-gray-300 -> #D1D5DB
                            borderRadius: '0.375rem',  // rounded-md -> 0.375rem
                            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)', // shadow-sm
                            outline: 'none',  // Asegura que no aparezca borde al enfocarse
                            transition: 'border-color 0.2s ease, box-shadow 0.2s ease', // Añade transición
                        }}
                        onFocus={(e) => {
                            e.target.style.borderColor = '#fd7e14'; // focus:border-indigo-500 -> #6366F1
                            e.target.style.boxShadow = '0 0 0 2px rgba(251, 107, 4, 0.4)'; // focus:ring-indigo-500
                        }}
                        onBlur={(e) => {
                            e.target.style.borderColor = '#D1D5DB'; // Regresa al color original
                            e.target.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.1)'; // Regresa a la sombra original
                        }}
                    >
                        <option value="">Todos</option>
                        <option value="validado">Validado</option>
                        <option value="en espera">En espera</option>
                        <option value="no validado">No validado</option>
                </select>
                {/* <br/><br/><br/><br/> */}
            <input
                type="text"
                placeholder="Buscar..."
                value={globalFilter ?? ''}
                onChange={(e) => setGlobalFilter(e.target.value)}
                style={{
                    width: '25%', 
                    paddingLeft: '2.5rem',   // pl-10 -> 2.5rem
                    paddingRight: '1rem',    // pr-4 -> 1rem
                    paddingTop: '0.5rem',    // py-2 -> 0.5rem
                    paddingBottom: '0.5rem', // py-2 -> 0.5rem
                    border: '1px solid #D1D5DB', // border-gray-300 -> #D1D5DB
                    borderRadius: '0.375rem',  // rounded-md -> 0.375rem
                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)', // shadow-sm
                    outline: 'none',  // Asegura que no aparezca borde al enfocarse
                    transition: 'border-color 0.2s ease, box-shadow 0.2s ease', // Añade transición
                  }}
                  onFocus={(e) => {
                    e.target.style.borderColor = '#fd7e14'; // focus:border-indigo-500 -> #6366F1
                    e.target.style.boxShadow = '0 0 0 2px rgba(251, 107, 4, 0.4)'; // focus:ring-indigo-500
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = '#D1D5DB'; // Regresa al color original
                        e.target.style.boxShadow = '0 1px 2px rgba(0, 0, 0, 0.1)'; // Regresa a la sombra original
                  }}
            />
            </div>
        {/* <div className='overflow-x-auto bg-white shadow-md rounded-lg'> */}
            <table style={{ minWidth: '100%', borderCollapse: 'collapse', border: '1px solid #e5e7eb'  }}>
            {/* <table className='min-w-full divide-y divide-gray-200'> */}
                <thead className='bg-orange'>
                {getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                        <th 
                            colSpan={header.colSpan} 
                            key={header.id}
                            className='px-6 py-3 text-center text-xs form-control-md text-white tracking-wider'
                        >{
                            <div
                                {
                                    ...{
                                        className: header.column.getCanSort()
                                        ? "cursor-pointer select-none flex items-center"
                                        : "",
                                        onClick: header.column.getToggleSortingHandler()
                                    }
                                }
                            >
                            {   
                                flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )
                            }
                            {'↑↓'}
                            </div>
                        }
                        </th>
                    ))}
                    </tr>
                ))}
                </thead>

            <tbody className='bg-white divide-y divide-gray-200'>
            {
                getRowModel()?.rows?.length 
                    ?   (
                        getRowModel().rows.map(row => (
                        <tr 
                        key={row.id}
                        // className='hover:bg-black-50'
                        style={{ cursor: 'pointer', transition: 'background-color 0.2s', ':hover': { backgroundColor: '#f3f4f6' } }}
                        >
                            {row.getVisibleCells().map(cell => {
                                const isStatusColumn = cell.column.id === 'status';
                                const statusValue = cell.getValue();
                                let statusStyle = '';

                                if(isStatusColumn){
                                    statusStyle = getStatusClass(statusValue);
                                }
                                return (    
                                    <td 
                                    key={
                                        cell.id
                                    }
                                    style={{
                                        padding: '0.75rem 0.5rem',
                                        whiteSpace: 'nowrap',
                                        fontSize: '0.875rem',
                                        color: '#6b7280',
                                    }}
                                    >
                                        {view === 'accesorios' && (
                                            <>
                                            {cell.column.id !== 'factura' && (
                                                <span className={statusStyle}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </span>
                                            )}
                                            {cell.column.id === 'status' && (
                                                <Button
                                                    className="btn btn-secondary btn-sm mx-3"
                                                    onClick={() =>
                                                      handleUpdateStatus(row)
                                                    }
                                                    style={{
                                                      backgroundColor: "#6c757d",
                                                      borderColor: "#6c757d",
                                                      color: "#fff",
                                                    }}
                                                >
                                                    Actualizar
                                                </Button>
                                            )}
                                            {cell.column.id === 'factura' && (
                                                <Button
                                                className="btn btn-secondary btn-sm"
                                                onClick={() => handleViewFactura(cell.getValue())}
                                                style={{
                                                    backgroundColor: "#6c757d",
                                                    borderColor: "#6c757d",
                                                    color: "#fff",
                                                }}
                                                >
                                                Ver factura
                                                </Button>
                                            )}
                                            </>
                                        )}
                                    </td>
                                )
                            })}
                        </tr>
                        ))
                        )
                    : (
                    <tr>
                        <td colSpan={columns.length} className="text-center py-4 text-black">
                            No hay datos disponibles.
                        </td>
                    </tr>
                    )
                }
            </tbody>
        </table>
        </div>
        <div
        className='d-flex justify-content-between align-items-center mt-4 text-sm text-black'
        >
            <div 
            className='d-flex align-items-center mb-4 mb-sm-0'
            // className="flex items-center mb-4 sm:mb-0"
            >
                <span>
                    Número de resultados por página&nbsp;
                    {/* Página {pageIndex + 1} de {getRowModel().pageCount} */}
                </span>
                <select
                    className='border border-dark rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 p-2'
                    value={getState().pagination.pageSize}
                    onChange={(e)=>{
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {
                    pageSizeOptions.map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))
                    }
                </select>
            </div>
            <div 
            style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                <button 
                style={{
                    padding: '0.5rem',
                    borderRadius: '0.375rem', // rounded-md
                    backgroundColor: '#f3f4f6', // bg-light
                    color: '#000000', // text-black
                    cursor: 'pointer',
                    transition: 'background-color 0.2s ease',
                  }}
                // className='p-2 rounded-md bg-light text-black hover:bg-black-50 disabled:opacity-50'
                onClick={()=>setPageIndex(0)}
                disabled={!getCanPreviousPage()}
                >
                    {'<<'}
                </button>
                <button 
                style={{
                    padding: '0.5rem',
                    borderRadius: '0.375rem', // rounded-md
                    backgroundColor: '#f3f4f6', // bg-light
                    color: '#000000', // text-black
                    cursor: 'pointer',
                    transition: 'background-color 0.2s ease',
                }}
                // className='p-2 rounded-md bg-light text-black hover:bg-black-50 disabled:opacity-50'
                onClick={() => previousPage()} 
                disabled={!getCanPreviousPage()}>
                    {'<'}
                </button>
                <span 
                className='flex align-items-center'>
                    <input 
                        min={1}
                        max={getPageCount()}
                        type='number'
                        value={getState().pagination.pageIndex + 1}
                        onChange={(e)=>{
                            const page = e.target.value 
                                            ? Number(e.target.value) - 1
                                            : 0;
                            setPageIndex(page)
                        }}
                        // className='w-16 p-2 rounded-md border border-light text-center'
                        style={{
                            width: '4rem', // w-16
                            padding: '0.5rem',
                            borderRadius: '0.375rem', // rounded-md
                            border: '1px solid #e5e7eb', // border-light
                            textAlign: 'center',
                        }}
                    />
                    <span className='ml-1'>de {getPageCount()}</span>
                </span>
                <button
                 style={{
                    padding: '0.5rem',
                    borderRadius: '0.375rem', // rounded-md
                    backgroundColor: '#f3f4f6', // bg-gray-100
                    color: '#4b5563', // text-gray-600
                    cursor: 'pointer',
                    transition: 'background-color 0.2s ease',
                }}
                // className='p-2 rounded-md bg-gray-100 text-gray-600 hover:bg-gray-200 disabled:opacity-50'
                onClick={() => nextPage()} 
                disabled={!getCanNextPage()}
                > 
                    {'>'}
                </button>
                <button 
                style={{
                    padding: '0.5rem',
                    borderRadius: '0.375rem', // rounded-md
                    backgroundColor: '#f3f4f6', // bg-gray-100
                    color: '#4b5563', // text-gray-600
                    cursor: 'pointer',
                    transition: 'background-color 0.2s ease',
                }}
                // className='p-2 rounded-md bg-gray-100 text-gray-600 hover:bg-gray-200 disabled:opacity-50'
                onClick={() => setPageIndex(getPageCount() - 1)}
                disabled={!getCanNextPage()}> 
                    {'>>'}
                </button>
            </div>
        </div>
    </div>
  );
};

export default DynamicTable;
