import React, { useState } from "react";
//import { useLocation } from "wouter";
import { Link, useNavigate } from "react-router-dom";
import useUser from "hooks/useUser";
import { useEffect } from "react";
import csrfService from "services/csrf";
import "./login.css";
import { Container } from "react-bootstrap";
import { Alert, Card } from "reactstrap";
import Logo from "components/Logo";

export default function Login({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  //const [, navigate] = useLocation();
  let navigate = useNavigate();
  const { isLoginLoading, hasLoginError, login, isLogged, Roles } = useUser();

  useEffect(() => {
    if (isLogged) {
      if (Roles === 'Vendedor' || Roles === 'Propietario') {
        navigate("/dashboard-vendedor");
      } else if (Roles === 'Analista') {
        navigate("/productos-registrados");
      } else if (Roles === 'Admin') {
        navigate("/dashboard");
      }  else if (Roles === 'Promotor') {
        navigate("/dashboard-vendedorEspecializado");
      } 
      
      onLogin && onLogin();
    }
  }, [isLogged, navigate, onLogin, Roles]);

  const handleSubmit = (e) => {
    e.preventDefault();
    csrfService().then(() => {
      login({ username, password });
    });
  };

  return (
    <React.Fragment>

      <div className="login__background">
        <Container>
          <div className="d-flex justify-content-center align-items-center vh-100">
            <form className="login" onSubmit={handleSubmit}>
              <Logo className="d-block img-fluid w-75 mx-auto" />
              <h3 className="titulo_login">Inicio de sesión</h3>
              <ul className="login__links">
                <li><Link to="/login"> Inicio de sesión</Link> </li>
                <li><Link to="/register"> Registro </Link> </li>
                <li><Link to="/recuperar"> Recuperar contraseña </Link> </li>
              </ul>


              <input
                className="login__input"
                placeholder="Correo eléctronico"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />

              <input
                className="login__input"
                type="password"
                placeholder="Contraseña"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <button className="btn-primary d-block mx-auto my-3">Iniciar sesión</button>
              {hasLoginError && <div className="alert alert-danger" role="alert">
                Credentials are invalid.
              </div>}
            </form>


          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
