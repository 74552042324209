import Equivalence from 'components/EquivalenceList'
import React from 'react'


export default function EquivalencePage() {
  return (
    <div className='container'>
      
      <Equivalence />
    </div>
  )
}
