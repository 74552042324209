import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useRanking from "../../hooks/useRanking";
import "./dashboard_vendedor.css";
import usuario from "../../assets/images/Usuario.png";
import Escolaridad from "components/Escolaridad";
import VentasPromotor from "components/Dashboard/VentasPromotor";

export default function Dashboard() {
  const { tokenFull, IdUser, Puntos, Roles, UserNombre, Foto, Concentrados } =
    useUser();
  const { error, loading, message, Posicion, RankingGetService } = useRanking();
  const [Vista, setVista] = useState(0);
  const navigate = useNavigate();
  const [CurrentPagina, setCurrentPagina] = useState(1);
  const [Pagina, setPagina] = useState(0);

  const goToRoute = (ruta) => {
    navigate("/" + ruta);
  };

  const ENDPOINT = process.env.REACT_APP_BASE_URL;

  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    if (Roles === "promotor") {
      setModalOpen(true);
    }
  }, [Roles]);

  const redirectToNewSite = () => {
    window.location.href = "https://especialistademarca.contactostihl.com/";
  };

  return (
    <>
      <div className="login__background">
        <div className="container fondo_blanco">
          <div className="row">
            <div className="col-md-6">
              <h2>
                {Foto == null || Foto == "null" ? (
                  <img width={65} src={usuario} className="image_usuario" />
                ) : (
                  <img
                    width={65}
                    src={ENDPOINT + "/images/foto/" + Foto}
                    className="image_usuario rounded-circle"
                  />
                )}

                {UserNombre}
              </h2>
            </div>
            <div className="col-md-6 text-end"></div>
            <div className="col-md-12">
              <hr />
            </div>
          </div>
          <br />
          {/*           <div className="row">
          <div className="col-md-12" >

            <button
              type="button"
              className="btn btn-dark"
              style={{ marginBottom: 20 }}
                onClick={() => goToRoute("registrar-producto")}
            >
              Registra tu venta
            </button>
            <button
              type="button"
              className="btn btn-dark"
              style={{ marginBottom: 20 }}
              onClick={() => goToRoute("editar-cuenta")}
            >
              Editar mis datos
            </button>
          </div>
        </div> */}

          <div className="row">
            <div className="col-md-12" style={{ padding: "25px" }}>
              <VentasPromotor />
            </div>
          </div>

          {modalOpen && (
            <>
              <div className="modal-backdrop show"></div>
              <div className="modal show d-block" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Atención</h5>
                    </div>
                    <div className="modal-body">
                      <p>
                        Te informamos que el sistema para registrar tus ventas
                        ahora se encuentra en una nueva dirección:
                      </p>
                      <p>
                        🌐<a href="https://especialistademarca.contactostihl.com/" > https://especialistademarca.contactostihl.com/ </a>
                      
                      </p>
                      <p>
                        Si eres especialista de marca, es imprescindible que
                        utilices esta nueva URL para realizar tus registros
                        correctamente.
                      </p>

                      <p>
                        Asegúrate de guardar esta dirección y, si necesitas
                        asistencia o tienes dudas, no dudes en contactarnos.
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={redirectToNewSite}
                      >
                        Ir al nuevo sitio
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <Escolaridad />
        </div>
      </div>
    </>
  );
}
