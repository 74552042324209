import React, { useEffect, useContext, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Context from "context/UserContext";
import { transfers } from "services/deposit";
import { ModalForm } from "components/ModalForm";
import EstatusForm from "./estatus";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import Select from "react-select";

const columns = [
  { id: "id", label: "Fecha de solicitud", minWidth: 170 },
  { id: "fecha", label: "Nombre", minWidth: 100 },
  { id: "Serial", label: "Monto", minWidth: 100 },
  { id: "Modelo", label: "Banco", minWidth: 100 },
  { id: "Puntos", label: "N. Tarjeta", minWidth: 100 },
  { id: "Factura", label: "CLABE interbancaria", minWidth: 100 },
  { id: "Status", label: "Estatus", minWidth: 100 },
  { id: "Justificación", label: "", minWidth: 100 },
];

const Meses = [
  { value: "1", label: "Enero" },
  { value: "2", label: "Febrero" },
  { value: "3", label: "Marzo" },
  { value: "4", label: "Abril" },
  { value: "5", label: "Mayo" },
  { value: "6", label: "Junio" },
  { value: "7", label: "Julio" },
  { value: "8", label: "Agosto" },
  { value: "9", label: "Septiembre" },
  { value: "10", label: "Octubre" },
  { value: "11", label: "Noviembre" },
  { value: "12", label: "Diciembre" },
];

export default function ListDeposits() {
  const [depositos, setDepositos] = useState([]);
  const { token } = useContext(Context);

  const [searchTerm, setSearchTerm] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = (flag) => setOpenModal(flag);
  const [edit, setEdit] = useState({});
  const [CurrentPagina, setCurrentPagina] = useState(1);
  const [Pagina, setPagina] = useState(0);
  const [SelectedMes, setSelectedMes] = React.useState("");

  useEffect(() => {
    get_depositos();
  }, []);

  const get_depositos = () => {
    const date = new Date();
    const mes = date.getMonth() + 1;
    const mesActual = mes < 10 ? `${mes}` : mes;

    transfers(token, mesActual-1).then(({ data }) => {
      setDepositos(data);
    });
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const BuscarPorMes = (mesActual) => {
    transfers(token, mesActual).then(({ data }) => {
      setDepositos(data);
    });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <h2 className="titulo2">Depositos</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Meses</label>
            <Select
              value={SelectedMes}
              onChange={(e) => {
                setSelectedMes(e);
                BuscarPorMes(e.value);
              }}
              options={Meses}
              placeholder={"Seleccionar Meses"}
            />
          </div>
          <div className="col-md-4 mb-5">
            <label>Buscar</label>
            <input
              type="text"
              className="form-control"
              value={searchTerm}
              placeholder="Buscar por nombre"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="col-md-12">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        className="text-center"
                        style={{ background: "#ed7100" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {depositos
                    .filter((row) => {
                      if (searchTerm === "") {
                        return row;
                      } else {
                        const buscadorLower = searchTerm;
                        const titleLower = row?.user?.nombre + ' ' + row?.user?.apellido_paterno + ' ' + row?.user?.apellido_materno;
                        const searchLower= buscadorLower.toLowerCase();
                        const fullName = titleLower.toLowerCase();
                        if (fullName?.includes(searchLower)) return row;
                      }
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          key={index?.user_id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell key={index} className="text-center">
                            {row.fecha}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {`${row?.user?.nombre} ${row?.user?.apellido_paterno} ${row?.user?.apellido_materno}`}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            ${row.monto} MNX
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {row?.user?.cards?.bank}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {row?.user?.cards?.card_number}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {row?.user?.cards?.interbank_key}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {row.status}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            <Button
                              variant="primary"
                              onClick={() => {
                                setEdit(row);
                                setOpenModal(true);
                              }}
                            >
                              Editar
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "Todos", value: -1 },
                      ]}
                      colSpan={10}
                      count={depositos.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "filas por página",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      {openModal && (
        <ModalForm
          show={openModal}
          title="Editar estatus"
          handleModal={handleModalClose}
        >
          <EstatusForm
            show={handleModalClose}
            get_deposit={get_depositos}
            transfer={edit}
          />
        </ModalForm>
      )}
    </>
  );
}
