import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { Link } from 'react-router-dom';

export default function ModalFactura({
    modal,
    toggle,
    Id,
    Imagen,
    setId,
    setImagen }) {

    const ENDPOINT = process.env.REACT_APP_BASE_URL;


    return <>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Imagen de Factura<br/>
                <Link className='btn btn-info' to={'/'+ENDPOINT + '/images/facturas/' + Imagen} target="_blank" download>Descargar Factura</Link>
                </ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12">
                        <InnerImageZoom src={ENDPOINT + '/images/facturas/' + Imagen} zoomSrc={ENDPOINT + '/images/facturas/' + Imagen} />
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </>
}
