import { useCallback, useState, useContext  } from 'react';
import { ComprasAdd, ComprasGet } from '../services/compra';
import Context from 'context/UserContext'

export default function useCompras() {
    const [Compras, setCompras] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });
    const { setPuntos} = useContext(Context)

    const ComprasAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        ComprasAdd(
            data, token
        ).then(response => {
            if (response.guardado == true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Compra registrada con exito',
                    success: true
                })
                setPuntos(response.puntos);
                window.sessionStorage.setItem('puntos', response.puntos)

            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al registrar compra',
                    success: false
                })
            }
        })
    }, [])

    const ComprasGetService = useCallback(({ IdUser, tokenFull }) => {
        ComprasGet({ IdUser, tokenFull }).then(response => {
            setCompras(response)
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Compras,
        ComprasAddService,
        ComprasGetService
    }
}