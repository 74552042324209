import React, { useEffect, useContext, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Context from "context/UserContext";
import api from "services/equivalences";
import ItemEquivalence from "../EquivalenceItem";
import { ModalForm } from "components/ModalForm";
import EquivalenceForm from "components/EquivalenceForm";
import EquivalenceEditar from "components/EquivalenceEditar";

export default function Equivalence() {
  const [equivalences, setEquivalences] = useState([]);
  const { token } = useContext(Context);

  const [openModal, setOpenModal] = useState(false);
  const [addPoints, setAddPoints] = useState(false);
  const handleModalClose = (flag) => setOpenModal(flag);
  const [editTool, setEditTool] = useState({});

  useEffect(() => {
    get_equivalences();
  }, []);

  const get_equivalences = () => {
    api.equivalences(token).then(({ data }) => {
      setEquivalences(data);
    });
  };

  const delete_item = (id) => {
    const opcion = window.confirm("¿Estas seguro de eliminar?");
    if (opcion === true) {
      api.deleteEquivalence(token, id).then((data) => {
        get_equivalences();
      });
    }
  };
  const edit_item = (id) => {    
    const itemEdit = equivalences.find(item => item.id== id)
    setEditTool(itemEdit);            
    setAddPoints(true);    
    setOpenModal(true);
  
  };
  const rowEquivalence = equivalences.map((item) => (
    <ItemEquivalence
      key={item.id}
      {...item}
      onDelete={delete_item}
      onEdit={edit_item}
    />
  ));


  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Button
            className="float-end my-3"
            variant="primary"
            onClick={() => {
              setAddPoints(false);
              handleModalClose(true);
            }}
          >
            Agregar
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="tabla">
            {rowEquivalence}
          </div>
          {openModal && (
            <ModalForm
              show={openModal}
              title="Equivalencias"
              handleModal={handleModalClose}
            >
              {!addPoints ? (
                <EquivalenceForm
                  show={handleModalClose}
                  get_equivalences={get_equivalences}
                />
              ) : (
                <EquivalenceEditar {...editTool}
                show={handleModalClose}
                  get_equivalences={get_equivalences}
                />
                
              )}
            </ModalForm>
          )}
        </Col>
      </Row>
    </Container>
  );
}
