
const ENDPOINT = process.env.REACT_APP_API_URL;

export const getReportData = async (mes, anio, tipoReporte, token) => {
  try {
    const response = await fetch(`${ENDPOINT}/reports?mes=${mes}&anio=${anio}&tipoReporte=${tipoReporte}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}` 
      }
      });
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error al obtener los datos del reporte: ${errorText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error al obtener los datos del reporte:", error);
    return null;
  }
};

export const downloadExcel = async (mes, anio, tipoReporte,token) => {
  try {
    const response = await fetch(`${ENDPOINT}/reports/download?mes=${mes}&anio=${anio}&tipoReporte=${tipoReporte}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json ',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error al descargar el reporte: ${errorText}`);
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `reporte_${tipoReporte}_${mes}_${anio}.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error("Error al descargar el reporte:", error);
  }
};