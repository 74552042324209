
import React from "react";
import { Helmet } from "react-helmet";




export default function ErrorPage() {
  

  return (
    <>
      <Helmet>
        <title>Error 404 | Giffy</title>
      </Helmet>
      <header className="o-header">
    
      </header>
      <div className="App-wrapper">
        <div >
            <span>404</span>
            <span>Sometimes gettings lost isn't that bad</span>
            
        </div>
      </div>
    </>
  );
}
