import { useEffect, useState } from 'react';
import { InputGroup } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { actualizarDatos, obtenerDatos } from "services/editarAccount";
import statesService from 'services/states';
import registerService from "services/register";
import useUser from "hooks/useUser";
import { urlBase } from '../../utilities/endpoints';
import avatar from "assets/images/Usuario.png";
import { es } from 'date-fns/locale';
import { format, subYears } from 'date-fns';
import Swal from 'sweetalert2';
import { schema } from './accountSchema';
import Select from 'react-select';

const gender = [
    { value: "Masculino", label: "Masculino" },
    { value: "Femenino", label: "Femenino" },
];

export const EditarCuenta = () => {    
    const [listDealers, setListDealers] = useState([]);
    const [states, setStates] = useState([]);
    const [photo, setPhoto] = useState('');
    const { tokenFull, IdUser } = useUser();
    
    const {
        control,
        register, 
        handleSubmit, 
        formState:{errors}, setValue, setError, watch} = useForm({
        defaultValues:{
          profilePhoto: '',
          name: '',
          firstName: '',
          lastName: '',
          phoneNumber:'',
          email:'',
          gender:'',
          progenitor:'',
          birthday: '',
          dealer:'',
          street:'',
          streetNumber:'',
          suiteNumber:'',
          zipCode: '',
          neighborhood:'',
          city:'',
          state:'',
          bank:'',
          cardFirst:'',
          cardSecond:'',
          cardThird:'',
          cardFourth:'',
          clabe:''
        },
        resolver: yupResolver(schema),
        mode: 'onChange', 
    })

  const profilePhoto = watch('profilePhoto');
    
  useEffect(() => {
    const fetchData = async () => {
        const states = await statesService();
        setStates(states);

        const dealers = await registerService.dealers();
        setListDealers(dealers);

        
        const data = await obtenerDatos({ IdUser, tokenFull });
        setPhoto(data.foto);
        setValue('name',data.nombre);
        setValue('firstName',data.apellidoPaterno);
        setValue('lastName',data.apellidoMaterno);
        setValue('phoneNumber',data.celular);
        setValue('email',data.email);
        setValue('gender',data.genero);
        setValue('progenitor',data.progenitor);
        setValue('birthday',new Date(data.cumpleanos + 'T00:00:00'));

        const dealer = dealers.find(dealer=>dealer.value===data.distribuidor)
        setValue('dealer', {value: data.distribuidor, label: dealer.label})
        
        setValue('street', data.calle);
        setValue('streetNumber', data.numeroExterior);
        if(data.numeroInterio){
            setValue('suiteNumber', data.numeroInterior);
        }
        setValue('zipCode', data.codigoPostal);
        setValue('neighborhood', data.colonia);
        setValue('city', data.ciudad);
        
        const state = states.find(state=>state.value===data.estado)
        setValue('state', {value:data.estado, label: state.label});

        setValue('bank', data.banco);
        setValue('cardFirst', data.firstDigits);
        setValue('cardSecond',data.secondDigits);
        setValue('cardThird',data.thirdDigits);
        setValue('cardFourth',data.fourthDigits);
        if(data.clabe){
            setValue('clabe', data.clabe);
        }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (profilePhoto && profilePhoto[0]) {
        setPhoto('');
        const objectUrl = URL.createObjectURL(profilePhoto[0]);
        setPhoto(objectUrl);
        // Liberar la URL cuando se desmonte el componente
        return () => URL.revokeObjectURL(objectUrl);
    }else{
        setPhoto('');
    }
  }, [profilePhoto]);

    const handleInputChange = (e, field) => {
        e.preventDefault();
        let value = e.target.value.replace(/\D/g, ''); // \D remueve cualquier cosa que no sea un número
        // Solo permite números
        if (field === 'cardFirst' && value.startsWith('0')) {
            value = value.replace(/^0/, ''); // Remueve el primer cero si existe
        }

        setValue(field, value); // Actualiza el valor del campo con solo números
    };

    const onSubmit = handleSubmit(async (data,e) => {
        e.preventDefault();
        let correcto = true;
        let cardNumber = '';
        data.id = IdUser;
        data.birthday = format(data.birthday, "yyyy/MM/dd");
            
        if(data.cardFirst || data.cardSecond || data.cardThird || data.cardFourth){
            cardNumber = data.cardFirst.concat(
                data.cardSecond,
                data.cardThird,
                data.cardFourth
            );
        }
        
        if(cardNumber){
            if((cardNumber).length === 16){
                data.cardNumber = cardNumber;
            }else{
                setError('cardFirst',{
                    message: 'Rellenar correctamente los datos de su tarjeta'
                })
                correcto = false;
            }
        }

        if(data.profilePhoto && data.profilePhoto[0] !== undefined){
            data.profilePhoto = data.profilePhoto[0];
        }

        if(data.dealer){
            data.dealer = data.dealer.value;
        }
        
        if(data.state){
            data.state = data.state.value;
        }
        
        delete data.cardFirst;
        delete data.cardSecond;
        delete data.cardThird;
        delete data.cardFourth;

        const formData = new FormData();
        for(let key in data){
            formData.append(key, data[key]);
        }
        if(correcto){
            const result = await actualizarDatos(formData, tokenFull);
            if(result.response){
                if(result.response.estado==="Ok"){
                    Swal.fire({
                        icon: "success",
                        title: '<h5 style="color:#A5DC86">ACTUALIZADO</h5>',
                        text: result.response.mensaje,
                        showConfirmButton: false,
                        timer: 2500
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        buttonsStyling: true,
                        title: '<h5 style="color:#f27474">ERROR</h5>',
                        text: result.response.mensaje,
                        confirmButtonColor: '#ed7100',
                    })
                }
            }
            if(result.errors){
                for(const property in result.errors){
                    if(property==="cardNumber"){
                        setError('cardFirst',{
                            message: result.errors[property]
                        })
                    }else{
                        setError(property,{
                            message: result.errors[property]
                        })
                    }
                }
            }
        }
    })

    return <>
        <div className='container' style={{ paddingTop: 50, paddingBottom: 50 }}>
            <form onSubmit={onSubmit}>

            <div className='row'>
                <div className='col-md-4 text-center mx-auto'>
                    {   
                        (photo === '' || photo === null)
                            ?
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <img src={avatar} className="img-fluid mx-auto d-block" />
                            :
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <img src={
                                photo.includes('blob') 
                                ? photo
                                : urlBase + '/images/foto/' + photo
                            } style={{width: '140px', height: '140px', objectFit: 'cover'}}  className="img-fluid mx-auto d-block rounded-circle" />
                    }

                    <label style={{ marginTop: 10, marginBottom: 10 }}>Foto de perfil</label>
                    <input type='file' className='form-control' 
                        {...register('profilePhoto', {
                            validate:{
                                lessThan10MB: (files) =>
                                    files[0]?.size < 10000000 || "Max 10MB",
                                acceptedFormats: (files) =>
                                    ["image/jpeg", "image/jpg", "image/png", "image/gif"]
                                        .includes(files[0]?.type) || "Only PNG, JPEG, JPG e GIF",
                            } 
                        })}
                        accept='image/jpeg, image/jpg, image/png, image/gif' 
                    />
                    {errors.profilePhoto && <span className='text-danger'>{errors.profilePhoto.message}</span>}
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>
                        Nombre
                        <span className="text-danger">*</span>
                    </label>
                    <input type='text' className='form-control' {...register('name')} />
                    {errors.name && <span className='text-danger'>{errors.name.message}</span>}
                </div>
                <div className='col-md-4 text-center'>
                    <label>
                        Apellido Paterno
                        <span className="text-danger">*</span>
                    </label>
                    <input type='text' className='form-control' {...register('firstName')} />
                    {errors.firstName && <span className='text-danger'>{errors.firstName.message}</span>}
                </div>
                <div className='col-md-4 text-center'>
                    <label>
                        Apellido Materno
                        <span className="text-danger">*</span>
                    </label>
                    <input type='text' className='form-control' {...register('lastName')} />
                    {errors.lastName && <span className='text-danger'>{errors.lastName.message}</span>}
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>
                        Celular
                        <span className="text-danger">*</span>
                    </label>
                    <input type='text' className='form-control' maxLength={10}
                        {...register('phoneNumber')}
                        onChange={(e) => handleInputChange(e, 'phoneNumber')}
                    />
                    {errors.phoneNumber && <span className='text-danger'>{errors.phoneNumber.message}</span>}
                </div>
                <div className='col-md-4 text-center'>
                    <label>
                        Email
                        <span className="text-danger">*</span>
                    </label>
                    <input type='email' className='form-control' 
                        {...register('email')} 
                        disabled 
                    />
                    {errors.email && <span className='text-danger'>{errors.email.message}</span>}
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>
                        Género
                        <span className="text-danger">*</span>
                    </label>
                    <select {...register('gender')} className='form-control'>
                        <option value="" disabled>Seleccione</option>
                        {
                            gender.map((element,key) => {
                                return <option key={key} value={element.value}>{element.label}</option>
                            })
                        }
                    </select>
                    {errors.gender && <span className='text-danger'>{errors.gender.message}</span>}
                </div>
                <div className='col-md-4 text-center'>
                    <label>
                        Soy Padre/Madre de familia
                        <span className="text-danger">*</span>
                    </label>
                    <select {...register('progenitor')} className='form-control'>
                        <option value="" disabled>Seleccione</option>
                        <option value='Si'>Sí</option>
                        <option value='No'>No</option>
                    </select>
                    {errors.progenitor && <span className='text-danger'>{errors.progenitor.message}</span>}
                </div>
                <div className='col-md-4 text-center'>
                    <label>
                        Cumpleaños
                        <span className="text-danger">*</span>
                    </label>
                    <Controller
                        control={control}
                        name="birthday"
                        render={({field})=>(
                            <DatePicker 
                            autoComplete="off"
                            {...field}
                            selected={field.value}
                            onChange={(date)=> setValue('birthday', date)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd-MM-yyyy"
                            minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 99))}
                            maxDate={subYears(new Date(),18)}
                            locale={es}
                            placeholderText="Selecciona tu cumpleaños"
                            className="form-control"
                            />
                        )}
                    />
                    {errors.birthday && <span className='text-danger'>{errors.birthday.message}</span>}
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>
                        Distribuidor
                        <span className="text-danger">*</span>
                    </label>
                    <Controller
                        control={control}
                        name='dealer'
                        render={( {field })=>(
                            <Select
                            {...field}
                            options={listDealers}
                            onChange={(selectedOption)=>field.onChange(selectedOption)}
                            value={field.value}
                            closeMenuOnSelect={true}
                            placeholder={field.value ? field.value.label : "Seleccione"}
                            />
                        )}
                    />
                    {errors.dealer && <span className='text-danger'>{errors.dealer.message}</span>}
                </div>
                <div className='col-md-4 text-center'>
                    <p className="mt-4 pt-2">
                        Si no conoces el número de distribuidor, contáctanos al Whatsapp{" "}
                        <a
                            target="blank"
                            href="https://api.whatsapp.com/send?phone=5212225809898"
                        >
                            2225809898
                        </a>
                        . ¡Nosotros te ayudamos!
                    </p>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4'>
                    <h6>DIRECCIÓN PERSONAL</h6>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-5 text-center'>
                    <label>
                        Calle
                        <span className="text-danger">*</span>
                    </label>
                    <input type='text' className='form-control' {...register('street')} />
                    {errors.street && <span className='text-danger'>{errors.street.message}</span>}
                </div>
                <div className='col-md-2 text-center'>
                    <label>
                        Número exterior
                        <span className="text-danger">*</span>
                    </label>
                    <input type='text' className='form-control' {...register('streetNumber')} />
                    {errors.streetNumber && <span className='text-danger'>{errors.streetNumber.message}</span>}
                </div>
                <div className='col-md-2 text-center'>
                    <label>Número interior</label>
                    <input type='text' className='form-control' {...register('suiteNumber')} />
                    {errors.suiteNumber && <span className='text-danger'>{errors.suiteNumber.message}</span>}
                </div>
                <div className='col-md-3 text-center'>
                    <label>
                        Código postal
                        <span className="text-danger">*</span>
                    </label>
                    <input type='text' className='form-control' maxLength={5}
                        {...register('zipCode')}
                        onChange={(e) => handleInputChange(e, 'zipCode')}
                    />
                    {errors.zipCode && <span className='text-danger'>{errors.zipCode.message}</span>}
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>
                        Colonia
                        <span className="text-danger">*</span>
                    </label>
                    <input type='text' className='form-control' {...register('neighborhood')} />
                    {errors.neighborhood && <span className='text-danger'>{errors.neighborhood.message}</span>}
                </div>
                <div className='col-md-4 text-center'>
                    <label>
                        Ciudad
                        <span className="text-danger">*</span>
                    </label>
                    <input type='text' className='form-control' {...register('city')} />
                    {errors.city && <span className='text-danger'>{errors.city.message}</span>}
                </div>
                <div className='col-md-4 text-center'>
                    <label>
                        Estado
                        <span className="text-danger">*</span>
                    </label>
                    <Controller
                        control={control}
                        name='state'
                        render={( {field })=>(
                            <Select
                            {...field}
                            options={states}
                            onChange={(selectedOption)=>field.onChange(selectedOption)}
                            value={field.value}
                            closeMenuOnSelect={true}
                            placeholder={field.value ? field.value.label : "Seleccione"}
                            />
                        )}
                    />
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4'>
                    <h6>DATOS BANCARIOS</h6>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>
                        Nombre del Banco
                        <span className="text-danger">*</span>
                    </label>
                    <input type='text' className='form-control' {...register('bank')} />
                    {errors.bank && <span className='text-danger'>{errors.bank.message}</span>}
                </div>
                <div className='col-md-8 text-center'>
                    <label>Número de Tarjeta</label>
                    <InputGroup className="mb-3">
                        <input type='text' className='form-control text-center' maxLength={4} 
                            {...register('cardFirst')}
                            onChange={(e) => handleInputChange(e, 'cardFirst')}
                        />
                        <input type='text' className='form-control text-center' maxLength={4} 
                            {...register('cardSecond')}
                            onChange={(e) => handleInputChange(e, 'cardSecond')}
                        />
                        <input type='text' className='form-control text-center' maxLength={4}
                            {...register('cardThird')}
                            onChange={(e) => handleInputChange(e, 'cardThird')}
                        />
                        <input type='text' className='form-control text-center' maxLength={4}
                            {...register('cardFourth')}
                            onChange={(e) => handleInputChange(e, 'cardFourth')}
                        />
                    </InputGroup>
                    {(errors.cardFirst) && <span className='text-danger'>{errors.cardFirst.message}</span>}
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <label>CLABE interbancaria</label>
                    <input type='text' className='form-control' maxLength={18}
                        {...register('clabe')}    
                        onChange={(e) => handleInputChange(e, 'clabe')}
                    />
                    {errors.clabe && <span className='text-danger'>{errors.clabe.message}</span>}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 text-center'>
                    <button type='submit' className='btn btn-primary mb-4 mt-5'>
                        Actualizar
                    </button>
                </div>
            </div>
            </form>
        </div>
    </>
}