import React, { useState, useEffect } from 'react'
import usePremios from '../../hooks/usePremios';
import useUser from '../../hooks/useUser';
import ModalPremio from '../Modal/ModalPremio';

export default function HomePage() {
    const { success, message, error, Premios, PremiosGetService, PremiosDeleteService } = usePremios();
    const { tokenFull, IdUser, Puntos } = useUser();
    const [Pagina, setPagina] = useState(0);
    const [modal, setModal] = useState(false);
    const [Update, setUpdate] = useState(false);
    const [CurrentPagina, setCurrentPagina] = useState(1);
    const [Id, setId] = useState('');
    const [Categoria, setCategoria] = useState('');
    const [Nombre, setNombre] = useState('');
    const [Precio, setPrecio] = useState('');
    const [Descripcion, setDescripcion] = useState('');
    const [Link, setLink] = useState('');
    const ENDPOINT = process.env.REACT_APP_API_URL.substr(0, 43);

    const toggle = () => {
        setModal(!modal);
        setUpdate(false);
        setId('');
        setCategoria('');
        setNombre('');
        setPrecio('');
        setDescripcion('');
        setLink('');
    }

    useEffect(() => {
        if (Premios.length > 0) {
            setPagina(Math.round(Premios.length / 10));
        }
    }, [Premios])

    const getPaginatedData = () => {
        const startIndex = CurrentPagina * 10 - 10;
        const endIndex = startIndex + 10;
        return Premios.slice(startIndex, endIndex);
    };


    useEffect(() => {
        PremiosGetService(tokenFull)
    }, [PremiosGetService])

    const previousPage = () => {
        setCurrentPagina(CurrentPagina => CurrentPagina - 1);
    }

    const nextPage = () => {
        setCurrentPagina(CurrentPagina => CurrentPagina + 1);
    }

    const premioEliminar = (dato) => {
        const data = new FormData();
        data.append("id", dato);
        PremiosDeleteService(data, tokenFull);
    }

    useEffect(() => {
        if (success == true) {
            PremiosGetService(tokenFull)
        }
    }, [PremiosGetService, success])

    return <>
        <ModalPremio modal={modal}
            toggle={toggle}
            Id={Id}
            Update={Update}
            Categoria={Categoria}
            Nombre={Nombre}
            Precio={Precio}
            Descripcion={Descripcion}
            Link={Link}
            setCategoria={setCategoria}
            setNombre={setNombre}
            setPrecio={setPrecio}
            setDescripcion={setDescripcion}
            setLink={setLink}
            PremiosGetService={PremiosGetService}
        />
        <div className='container'>
        <br /><br />            
            <div className='row'>
                <div className='col-md-6'>
                    <h2>Listado de Premios</h2>
                </div>
                <div className='col-md-6'>
                    <a className="btn btn-primary float-end" onClick={toggle}>Agregar Premio</a>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-12'>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Imagen</th>
                                    <th>Categoria</th>
                                    <th>Nombre</th>
                                    <th>Precio</th>
                                    <th>Descripción</th>
                                    <th>Link</th>
                                    <th>Imagen 2</th>
                                    <th>Editar</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getPaginatedData().map((item, index) => {
                                        return <tr key={item.id}>
                                            <td>{index + 1}</td>
                                            <td><img style={{ width: 50 }} src={item.imagen} /></td>
                                            <td>{item.categoria}</td>
                                            <td>{item.product}</td>
                                            <td>{item.price}</td>
                                            <td>{item.description}</td>
                                            <td>{item.link}</td>
                                            <td><img style={{ width: 50 }} src={item.imagen_second} /></td>
                                            <td><button className='btn btn-warning' onClick={() => {
                                                setId(item.id);
                                                setCategoria(item.categoria);
                                                setNombre(item.product);
                                                setPrecio(item.price);
                                                setDescripcion(item.description);
                                                setLink(item.link);
                                                setUpdate(true);
                                                setModal(true);
                                            }}>Editar</button></td>
                                            <td><button className='btn btn-danger' onClick={(() => premioEliminar(item.id))}>Eliminar</button></td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-12'>
                    <button onClick={previousPage}>Previous Page</button>
                    <button onClick={nextPage}>Next Page</button>
                </div>
            </div>
        </div>
    </>
}