import React, { useState, useContext } from "react";
import {
  Form,
  Button,
  Alert,
  Spinner,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { getReportData, downloadExcel } from "services/reportService";
import "./DownloadReports.css";
import Context from "context/UserContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DownloadReports = () => {
  const [mes, setMes] = useState("");
  const [anio, setAnio] = useState(new Date().getFullYear());
  const [tipoReporte, setTipoReporte] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [dataEncontrada, setDataEncontrada] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { token } = useContext(Context);

  const handleBuscar = async () => {
    const newErrors = {};
    if (!mes) newErrors.mes = true;
    if (!anio) newErrors.anio = true;
    if (!tipoReporte) newErrors.tipoReporte = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setIsLoading(true);
    const data = await getReportData(mes, anio, tipoReporte, token);
    setIsLoading(false);

    if (data && data.length > 0) {
      setDataEncontrada(true);
      setMensaje("Datos encontrados");
    } else {
      setDataEncontrada(false);
      setMensaje("No se encontraron datos");
    }
  };

  const handleDescargar = async () => {
    setIsLoading(true);
    await downloadExcel(mes, anio, tipoReporte, token);
    setIsLoading(false);
  };

  const handleChange = (field, value) => {
    if (field === "mes") setMes(value);
    if (field === "anio") setAnio(value);
    if (field === "tipoReporte") setTipoReporte(value);

    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
    }
  };

  return (
    <Container className="download-reports">
      <Row className="justify-content-center">
        <Col md={8} className="fondo_blanco p-4">
          <h2 className="text-center titulo">Reportes</h2>
          <Form>
            <Form.Group>
              <Form.Label>Mes</Form.Label>
              <Form.Control
                as="select"
                value={mes}
                onChange={(e) => handleChange("mes", e.target.value)}
                isInvalid={errors.mes}
              >
                <option value="">Seleccione un mes</option>
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Seleccione un mes.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Año</Form.Label>
              <DatePicker
                selected={new Date(anio, 0, 1)}
                onChange={(date) => setAnio(date.getFullYear())}
                showYearPicker
                dateFormat="yyyy"
                minDate={new Date(2023, 0, 1)}
                maxDate={new Date()}
                className={`form-control ${errors.anio ? "is-invalid" : ""}`}
              />

              <Form.Control.Feedback type="invalid">
                Ingrese un año.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Tipo de Reporte</Form.Label>
              <Form.Control
                as="select"
                value={tipoReporte}
                onChange={(e) => handleChange("tipoReporte", e.target.value)}
                isInvalid={errors.tipoReporte}
              >
                <option value="">Seleccione un tipo de reporte</option>
                <option value="edm-sales-all">
                  Ventas EDM (validadas y no validadas)
                </option>
                <option value="seller-ranking">Ranking de vendedores</option>
                <option value="edm-accessories">Accesorios EDM</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Seleccione un tipo de reporte.
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleBuscar}
              style={{ marginTop: 20 }}
              disabled={isLoading}
            >
              {isLoading ? <Spinner animation="border" size="sm" /> : "Buscar"}
            </Button>
          </Form>

          {mensaje && (
            <Alert variant={dataEncontrada ? "success" : "danger"}>
              {mensaje}
            </Alert>
          )}

          {dataEncontrada && (
            <Button
              variant="success"
              onClick={handleDescargar}
              style={{ marginTop: 20 }}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Descargar Excel"
              )}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DownloadReports;
