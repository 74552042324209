import { urlActualizarUser } from "utilities/endpoints";

const ENDPOINT = process.env.REACT_APP_API_URL;

// trarer registros
export async function CuentaGet({ IdUser, tokenFull }) {
    return fetch(`${ENDPOINT}/cuentaGet/${IdUser}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': tokenFull,
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function DistribuidorGet({ tokenFull }) {
    return fetch(`${ENDPOINT}/auth/dealer`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': tokenFull,
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function EstadoGet({ tokenFull }) {
    return fetch(`${ENDPOINT}/auth/states`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': tokenFull,
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function CuentaUpdate(data, token) {
    return fetch(`${ENDPOINT}/cuentaUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        },
        method: 'post',
        body: data
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export const obtenerDatos = async({ IdUser, tokenFull }) => {
    return fetch(`${ENDPOINT}/obtenerDatos/${IdUser}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': tokenFull,
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function actualizarDatos(data, token) {
    try {
        const params = {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': token
            },
            body: data
            // body: JSON.stringify({
            //     ...data
                // name: data.name,
                // firstName: data.name,
                // lastName: data.name,
                // phoneNumber: data.name,
                // email: data.name,
                // gender: data.name,
                // progenitor: data.name,
                // birthday: data.name,
                // dealer: data.name,
                // street: data.name,
                // streetNumber: data.name,
                // suiteNumber: data.name,
                // zipCode: data.firstname,
                // neighborhood: data.lastname,
                // city: data.email,
                // state: data.password,
                // bank:data.bank,
                // card: data.card,
                // clabe: data.clabe,
                // foto: data.profilePhoto
            // })
        }
        const response = await fetch(urlActualizarUser, params);
        const result = await response.json();

        if (response.status !== 200) throw result

        return result;
    } catch (error) {
        return error
    }
    // .then(res => res.json()).then(response => {
    //     return response;
    // });
}