import {useCallback, useContext, useState} from 'react'
import Context from 'context/UserContext'
import loginService from 'services/login'

export default function useUser () {
  const { token, setToken, IdUser, setIdUser, Puntos, setPuntos, Concentrados, setConcentrados, Roles, setRoles, UserNombre, setUserNombre, Dealer, setDealer, Foto, setFoto} = useContext(Context)  
  const [state, setState] = useState({ loading: false, error: false })

  const login = useCallback(({username, password}) => {
    setState({loading: true, error: false })
    loginService({username, password})
      .then(({token, id_user, puntos, concentrado, rol, nombre, distribuidor, foto}) => {
        window.sessionStorage.setItem('token', token)
        window.sessionStorage.setItem('id_user', id_user)
        window.sessionStorage.setItem('puntos', puntos)
        window.sessionStorage.setItem('concentrado', concentrado)
        window.sessionStorage.setItem('rol', rol)
        window.sessionStorage.setItem('nombre', nombre)
        window.sessionStorage.setItem('distribuidor', distribuidor)
        window.sessionStorage.setItem('foto', foto)
        setState({loading: false, error: false })
        setToken(token)
        setIdUser(id_user)
        setPuntos(puntos)
        setConcentrados(concentrado)
        setRoles(rol)
        setUserNombre(nombre)
        setDealer(distribuidor)
        setFoto(foto)
      })
      .catch(err => {
        window.sessionStorage.removeItem('token')
        window.sessionStorage.removeItem('id_user')
        window.sessionStorage.removeItem('puntos')
        window.sessionStorage.removeItem('concentrado')
        window.sessionStorage.removeItem('rol')
        window.sessionStorage.removeItem('nombre')
        window.sessionStorage.removeItem('distribuidor')
        window.sessionStorage.removeItem('foto')
        setState({loading: false, error: true })
        console.error(err)
      })
  }, [setToken, setIdUser, setPuntos, setConcentrados, setRoles, setUserNombre, setDealer, setFoto])

  const logout = useCallback(() => {
    window.sessionStorage.removeItem('token')
    window.sessionStorage.removeItem('id_user')
    window.sessionStorage.removeItem('puntos')
    window.sessionStorage.removeItem('concentrado')
    window.sessionStorage.removeItem('rol')
    window.sessionStorage.removeItem('nombre')
    window.sessionStorage.removeItem('distribuidor')
    window.sessionStorage.removeItem('foto')
    setToken(null)
    setIdUser(null)
    setPuntos(null)
    setConcentrados(null)
    setRoles(null)
    setUserNombre(null)
    setDealer(null)
    setFoto(null)
  }, [setToken, setIdUser, setPuntos, setConcentrados, setRoles, setUserNombre, setDealer, setFoto])


  return {
    tokenFull: 'Bearer '+ token,
    isLogged: Boolean(token),
    isLoginLoading: state.loading,
    hasLoginError: state.error,
    IdUser,
    Puntos,
    Concentrados,
    Roles,
    UserNombre,
    Dealer,
    Foto,
    token: token,
    login,
    logout
  }
} 