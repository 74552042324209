import React, { useState, useEffect } from "react";
import useCompras from "../../hooks/useCompras";
import useUser from "../../hooks/useUser";
import toast, { Toaster } from "react-hot-toast";
import ModalCompra from "../Modal/ModalCompra";
import moto from "../../assets/images/Motosierra_hojas.png";
import hoja from "../../assets/images/Hojas.png";

export default function HomePage() {
  const { success, message, error, Compras, ComprasGetService } = useCompras();
  const { tokenFull, IdUser, Puntos } = useUser();
  const [modal, setModal] = useState(false);
  const [Pagina, setPagina] = useState(0);
  const [CurrentPagina, setCurrentPagina] = useState(1);
  const [Id, setId] = useState("");
  const [Imagen, setImagen] = useState("");
  const ENDPOINT = process.env.REACT_APP_API_URL.substr(0, 43);

  const toggle = () => {
    setModal(!modal);
    setId("");
    setImagen("");
  };

  useEffect(() => {
    if (Compras.length > 0) {
      setPagina(Math.round(Compras.length / 10));
    }
  }, [Compras]);

  const getPaginatedData = () => {
    const startIndex = CurrentPagina * 10 - 10;
    const endIndex = startIndex + 10;
    return Compras.slice(startIndex, endIndex);
  };

  useEffect(() => {
    ComprasGetService({ IdUser, tokenFull });
  }, [ComprasGetService]);

  const previousPage = () => {
    setCurrentPagina((CurrentPagina) => CurrentPagina - 1);
  };

  const nextPage = () => {
    setCurrentPagina((CurrentPagina) => CurrentPagina + 1);
  };

  const fecha = (fecha) => {
    let meses = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DIC",
    ];
    let a = fecha.substr(0, 4);
    let m = fecha.substr(5, 2);
    let d = fecha.substr(8);
    return d + " / " + meses[m - 1] + " / " + a;
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  return (
    <>
      <Toaster />
      <ModalCompra
        modal={modal}
        toggle={toggle}
        Id={Id}
        Imagen={Imagen}
        setId={setId}
      />
      <div className="container">
        <div className="row">
          <img className="hoja" src={hoja} />
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <h2 className="titulo2">Mis depósitos</h2>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <table className="table">
              <thead>
                <tr style={{ borderBottom: "2px solid rgb(237, 113, 0)" }}>
                  <th>#</th>
                  <th>Fecha</th>
                  <th>Monto</th>
                  <th>Status</th>
                  <th>Ver Depósito</th>
                </tr>
              </thead>
              <tbody>
                {getPaginatedData().map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{fecha(item.fecha.substr(0, 10))}</td>
                      <td>${formatNumber(item.monto)}</td>
                      <td>
                        {(item.status == "Solicitado" && (
                          <span className="badge bg-warning text-dark">
                            {item.status}
                          </span>
                        )) ||
                          (item.status == "Depositado" && (
                            <span className="badge bg-success">
                              {item.status}
                            </span>
                          )) ||
                          (item.status == "Rechazado" && (
                            <>
                              <span className="badge bg-danger">
                                {item.status}
                              </span>
                              <br />
                              <p>{item.descripcion}</p>
                            </>
                          ))}
                      </td>
                      <td>
                        <button
                          className="btn btn-warning"
                          onClick={() => {
                            setImagen(item.imagen);
                            setModal(true);
                          }}
                        >
                          Ver
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-light"
                style={{ background: "#ddd" }}
                type="button"
                onClick={previousPage}
              >
                Página Anterior
              </button>
              <button
                className="btn btn-light"
                style={{ background: "#ddd" }}
                type="button"
                onClick={nextPage}
              >
                Página Siguiente
              </button>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div className="row">
        <div className="col-md-4">
          <img className="img-fluid" src={moto} />
        </div>
      </div>
    </>
  );
}
