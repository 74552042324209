import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { Button } from "reactstrap";

export default function Note({ info, handleSave }) {
  const [note, setNote] = React.useState(info.nota);

  return (
    <React.Fragment>
      <FloatingLabel
        controlId="floatingTextarea"
        label="Notas de seguimiento"
        className="mb-3"
      >
        <Form.Control
          as="textarea"
          placeholder="Notas"
          value={note || ""}
          onChange={(e) => setNote(e.target.value)}
        />
      </FloatingLabel>

      <Button variant="success" onClick={() => handleSave(info.id, note)}>
        Guardar
      </Button>
    </React.Fragment>
  );
}
