import React from 'react'
import { Container } from 'reactstrap'
import List from '../../../components/List'

export default function Index() {

  return (
    <>

      
      <List />
    </>
  )
}