import React from "react";
import { Routes, Route } from "react-router-dom";
/* import Loader from "./components/Loader"; */
import Header from "./components/Header";
import PrivateRoute from "./route/PrivateRoute";
import Register from "./pages/Register";
import { EditarCuenta } from "./pages/Account/editAccount";
// import EditarCuenta from "./pages/Account/editar";
import Recuperar from "./pages/Recuperar";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import DashboardVendedor from "./pages/Dashboard/dashboard";
import DashboardVendedorEspecializado from "./pages/Dashboard/dashboard_vendedorEspecializado";
import Compras from "./pages/Compras";
import Venta from "./pages/Venta";
import ProductosRegistrados from "./pages/ProductosRegistrados";
import Account from "./pages/Account";
import EquivalencePage from "./pages/Equivalence";
import PoitsPage from "./pages/Points";
import Utility from "./pages/Utilidad";
import Categorias from "./pages/Categorias";
import Productos from "./pages/Productos";
import UserList from "./pages/Points/UserList";
import ErrorPage from "./pages/ErrorPage";
import { UserContextProvider } from "./context/UserContext";
import Home from "components/Home";
import DepositoPage from "./pages/DepositosAdmin";
import BuscarSerial from "./pages/BuscarProductos";
import Vendedor from "./pages/Vendedores";
import RegistroPromotor from "./pages/RegistroPromotor";
import RegistroPropietario from "./pages/RegistroPropietario";
import Redeem from "./pages/Redeem";
import AccountPromotor from "./pages/AccountPromotor";
import DownloadReports from "components/Reports/DownloadReports";
import Accessories from "./pages/Accessories/Accessories";
import ListadoGanadores from "./pages/Ganadores/listado";
import SurGanadores from "./pages/Ganadores/sur";
import NorteGanadores from "./pages/Ganadores/norte";
import CentroGanadores from "./pages/Ganadores/centro";

export default function App() {
  return (
    <UserContextProvider>
      <div className="App">
        <section className="container2">
          <Header />

          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<Register />} path="/register" />
            <Route element={<Home />} path="/home" />
            <Route
              element={<RegistroPromotor />}
              path="/vendedor-especializado"
            />
            <Route
              element={<RegistroPropietario />}
              path="/propietario"
            />

            <Route element={<Recuperar />} path="/recuperar" />
            <Route path="/" element={<PrivateRoute />}>
              <Route element={<Dashboard />} path="/dashboard" />
              <Route
                element={<DashboardVendedor />}
                path="/dashboard-vendedor"
              />
              <Route
                element={<DashboardVendedorEspecializado />}
                path="/dashboard-vendedorEspecializado"
              />
              <Route element={<EditarCuenta />} path="/editar-cuenta" />
              {/* <Route element={<EditarCuenta />} path="/editar-cuenta" /> */}
              <Route element={<Venta />} path="/registrar-producto" />
              <Route
                element={<ProductosRegistrados />}
                path="/productos-registrados"
              />
              <Route element={<DepositoPage />} path="/admin-depositos" />
              <Route element={<Compras />} path="/compras" />
              <Route element={<Account />} path="/accounts" />
{/*               <Route element={<PoitsPage />} path="/points" /> */}
{/*               <Route element={<Utility />} path="/utility" /> */}
              <Route element={<UserList />} path="/userList" />
              <Route element={<EquivalencePage />} path="/equivalencies" />
              <Route element={<Categorias />} path="/category" />
              <Route element={<Productos />} path="/product" />
              <Route element={<Redeem />} path="/canjear" />
              <Route element={<BuscarSerial />} path="/buscar-serial" />
              <Route element={<Vendedor />} path="/editar-vendedor" />
              <Route element={<AccountPromotor />} path="/promotors" />
              <Route element={<DownloadReports/>} path="/download-reports" />
              <Route element={<ListadoGanadores/>} path="/ganadores-mes" />
              <Route element={<NorteGanadores/>} path="/ganador-norte" />
              <Route element={<CentroGanadores/>} path="/ganador-centro" />
              <Route element={<SurGanadores/>} path="/ganador-sur" />
              <Route path="/accessories" element={<Accessories />} />
            </Route>
            <Route element={<ErrorPage />} path="*" />
          </Routes>
        </section>
      </div>
    </UserContextProvider>
  );
}
