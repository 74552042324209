import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

const ModalFactura = ({ modal, toggle, facturaUrl }) => {
  const isImage = /\.(jpg|jpeg|png|gif)$/i.test(facturaUrl);
  const isPdf = /\.pdf$/i.test(facturaUrl);

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Factura</ModalHeader>
      <ModalBody>
        {isImage && (
          <InnerImageZoom src={facturaUrl} zoomSrc={facturaUrl} />
        )}
        {isPdf && (
          <iframe
            src={facturaUrl}
            width="100%"
            height="500px"
            title="Factura PDF"
          />
        )}
        {!isImage && !isPdf && (
          <p>Formato de archivo no soportado</p>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalFactura;

