import { subYears } from 'date-fns';
import * as yup from 'yup';

const numberPattern = /^\d*$/;

export const schema = yup.object().shape({
        name: yup.string().required('El campo nombre es requerido').min(2, 'El campo nombre debe tener mínimo 2 caracteres'),
        firstName: yup.string().required('El campo apellido paterno es requerido').min(2, 'El campo apellido paterno debe tener mínimo 2 caracteres'),
        lastName: yup.string().required('El campo apellido materno es requerido').min(2, 'El campo apellido materno debe tener mínimo 2 caracteres'),
        phoneNumber: yup.string()
            .required('El campo celular es requerido')
            .matches(numberPattern, 'Solo se permiten números')
            .matches(/^\d{10}$/, 'El campo celular debe tener 10 digítos'),
        email: yup.string().required('El campo correo es requerido').matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Correo inválido'),
        gender: yup.string().required('El campo genero es requerido'),
        progenitor: yup.string().required('El campo soy Padre/Madre es requerido'),
        birthday: 
            yup.date()
            .typeError('Selecciona una fecha válida')
            .min(new Date(new Date().setFullYear(new Date().getFullYear() - 99)), 'Debes tener menos de 99 años.')
            .max(subYears(new Date(),18), 'Debes tener al menos 18 años.')
            .required('El campo cumpleaños es requerido'),
        dealer: yup.object().required('El campo distribuidor es requerido'),
        // dealer: yup.string().required('El campo distribuidor es requerido'),
        street: yup.string().required('El campo calle es requerido'),
        streetNumber: yup.string().required('El campo número exterior es requerido'),
        suiteNumber: yup.string(),
        zipCode: yup.string().required('El campo código postal es requerido'),
        neighborhood: yup.string().required('El campo colonia es requerido'),
        city: yup.string().required('El campo ciudad es requerido'),
        state: yup.object().required('El campo estado es requerido'),
        // state: yup.string().required('El campo estado es requerido'),
        bank: yup.string().required('El campo nombre del banco es requerido'),
        cardFirst: yup.string()
            .matches(/^(?!0\d)\d*$/, 'Solo se permiten números'),
        cardSecond: yup.string()
            .matches(numberPattern, 'Solo se permiten números'),
        cardThird: yup.string()
            .matches(numberPattern, 'Solo se permiten números'),
        cardFourth: yup.string()
            .matches(numberPattern, 'Solo se permiten números'),
        clabe: yup.string()
            .nullable()
            .test('clabe-length', 'El campo CLABE debe tener 18 dígitos', function(value) {
                // Si el campo está vacío (undefined, null o una cadena vacía), es válido
                if (!value) return true;
                // Si tiene valor, debe contener exactamente 18 dígitos numéricos
                return /^\d{18}$/.test(value);
            })
    })