import React from "react";
import { Button, Form } from "react-bootstrap";
import "./tabla.css";

export default function Account(props) {
  return (
    <tr key={props.id}>
      <td>
        <span>{props.num}</span>
      </td>
      <td>
        <span>{props.nombre_completo}</span>
      </td>
      <td>
        <span>{props.dealer_id}</span>
      </td>
      <td>
        <span>
          <Button
            variant="info"
            onClick={() => props.handleModal(props.id)}
            className="btn-bg--orange"
          >
            Información
          </Button>
        </span>
      </td>
      <td>
        <span>
          <Form>
            <Form.Check
              type="switch"
              id={`custom-switch-${props.id}`}
              label="Activar"
              value={props.status}
              checked={props.status ? 1 : 0}
              onChange={(e) => props.onActive(props.id, e.target.value)}
            />
          </Form>
        </span>
      </td>
      <td>
        <Form>
          <Button variant="info mt-2" onClick={() => props.onAddNote(props.id)}>
            Editar Nota
          </Button>
        </Form>
      </td>
      <td>{props.nota}</td>
      <td>
        <span>
          {props.created_at}
        </span>
      </td>
{/*       <td>
        <span>
          <Button

            variant="danger"
            onClick={() => props.onDelete(props.id)}
          >
            Eliminar
          </Button>
        </span>

      </td> */}
    </tr>
  );
}
