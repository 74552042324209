import React, { useState } from "react";
//import { useLocation } from "wouter";
import { Link, useNavigate } from "react-router-dom";
import useUser from "hooks/useUser";
import { useEffect } from "react";
import csrfService from "services/csrf";
import "./login.css";
import { Container } from "react-bootstrap";
import { Card } from "reactstrap";
import Logo from "components/Logo";
import puntos_naranjas from "../../assets/images/Puntos naranjas-banner actualizaciones.png";

export default function Home({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  //const [, navigate] = useLocation();
  let navigate = useNavigate();
  const { isLoginLoading, hasLoginError, login, isLogged, Roles } = useUser();

  const handleClick = () => {
    navigate("/register");
  };

  return (
    <React.Fragment>
      <div className="login__background">
        <Container>
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="login">
           
              <Logo className="d-block img-fluid w-75 mx-auto mb-5" />
      
              <button onClick={handleClick} className="btn-primary d-block mx-auto my-3 mt-5" >REGISTRATE AQUI</button>
              

            </div>

            {/* <img src={puntos_naranjas} className="img-fluid" /> */}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}
