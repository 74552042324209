const ENDPOINT = process.env.REACT_APP_BASE_URL;

export async function RecuperarPost(data) {
  return fetch(`${ENDPOINT}/api/auth/forget-password`, {
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}
