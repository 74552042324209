import { useCallback, useState } from 'react';
import { CategoriasPremiosGet, PremiosGet, PremiosAdd, PremiosUpdate, PremiosDelete } from '../services/premios';

export default function usePremios() {
    const [Categorias, setCategorias] = useState([]);
    const [Premios, setPremios] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const CategoriasGetService = useCallback((token) => {
        CategoriasPremiosGet(token).then(response => {
            setCategorias(response)
        })
    }, []);
    const PremiosGetService = useCallback((token) => {
        PremiosGet(token).then(response => {
            setPremios(response)
        })
    }, []);

    const PremiosAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PremiosAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let categoria_id = errores['categoria_id'];
                let nombre = errores['nombre'];
                let precio = errores['precio'];
                let descripcion = errores['descripcion'];
                let link = errores['link'];
                let imagen = errores['imagen'];
                let imagen2 = errores['imagen2'];
                if (categoria_id != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: categoria_id[0],
                        success: false
                    })
                }
                if (nombre != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: nombre[0],
                        success: false
                    })
                }
                if (precio != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: precio[0],
                        success: false
                    })
                }
                if (descripcion != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: descripcion[0],
                        success: false
                    })
                }
                if (link != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: link[0],
                        success: false
                    })
                }
                if (imagen != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: imagen[0],
                        success: false
                    })
                }
                if (imagen2 != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: imagen2[0],
                        success: false
                    })
                }
            } else {
                if (response.guardado == true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Premio registrado con exito',
                        success: true
                    })

                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar premio',
                        success: false
                    })
                }
            }
        })
    }, [])

    const PremiosUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PremiosUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let categoria_id = errores['categoria_id'];
                let nombre = errores['nombre'];
                let precio = errores['precio'];
                let descripcion = errores['descripcion'];
                let link = errores['link'];
                let imagen = errores['imagen'];
                let imagen2 = errores['imagen2'];
                if (categoria_id != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: categoria_id[0],
                        success: false
                    })
                }
                if (nombre != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: nombre[0],
                        success: false
                    })
                }
                if (precio != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: precio[0],
                        success: false
                    })
                }
                if (descripcion != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: descripcion[0],
                        success: false
                    })
                }
                if (link != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: link[0],
                        success: false
                    })
                }
                if (imagen != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: imagen[0],
                        success: false
                    })
                }
                if (imagen2 != null) {
                    setState({
                        loading: false,
                        error: true,
                        message: imagen2[0],
                        success: false
                    })
                }
            } else {
                if (response.guardado == true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Premio actualizado con exito',
                        success: true
                    })

                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar premio',
                        success: false
                    })
                }
            }
        })
    }, [])

    const PremiosDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PremiosDelete(
            data, token
        ).then(response => {
            if (response.guardado == true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Categoria eliminada con exito',
                    success: true
                })

            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar categoria',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Categorias,
        Premios,
        CategoriasGetService,
        PremiosGetService,
        PremiosAddService,
        PremiosUpdateService,
        PremiosDeleteService
    }
}