import React, { useState, useEffect } from "react";
import useProducts from "../../hooks/useProducts";
import useUser from "../../hooks/useUser";
import toast, { Toaster } from "react-hot-toast";
import ModalFactura from "../Modal/ModalFactura";
import ModalStatuss from "./Modals/ModalAdd";
import ModalDate from "./Modals/ModalFecha";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Chip,
  IconButton,
  TableFooter,
  TablePagination,
  Button,
  FormControlLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PrintIcon from "@mui/icons-material/PrintOutlined";
import { setDate } from "date-fns";
import Select from "react-select";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const columns = [
  { id: "id", label: "#", minWidth: 170 },
  { id: "id", label: "Cliente", minWidth: 170 },
  { id: "fecha", label: "Fecha", minWidth: 100 },
  { id: "Serial", label: "Serial", minWidth: 100 },
  { id: "Modelo", label: "Modelo", minWidth: 100 },
  { id: "Puntos", label: "Puntos", minWidth: 100 },
  { id: "Factura", label: "Factura", minWidth: 100 },
  { id: "Actualizar status", label: "Actualizar status", minWidth: 100 },
  { id: "Status", label: "Status", minWidth: 100 },
  { id: "Justificación", label: "Justificación", minWidth: 100 },
  { id: "Campaña", label: "Campaña", minWidth: 100 },
];

const Meses = [
  { value: "01", label: "Enero" },
  { value: "02", label: "Febrero" },
  { value: "03", label: "Marzo" },
  { value: "04", label: "Abril" },
  { value: "05", label: "Mayo" },
  { value: "06", label: "Junio" },
  { value: "07", label: "Julio" },
  { value: "08", label: "Agosto" },
  { value: "09", label: "Septiembre" },
  { value: "10", label: "Octubre" },
  { value: "11", label: "Noviembre" },
  { value: "12", label: "Diciembre" },
];

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function HomePage() {
  const {
    success,
    message,
    error,
    Ventas,
    ValidadoFull,
    NoValidadoFull,
    EnEsperaFull,
    ValidadoDay,
    NoValidadoDay,
    EnEsperaDay,
    RegistrosGetService,
    RegistroUpdateValidarFacturaService,
  } = useProducts();
  const { tokenFull, IdUser, Puntos } = useUser();
  const [modal, setModal] = useState(false);
  const [ModalStatus, setModalStatus] = useState(false);
  const [ModalDates, setModalDates] = useState(false);
  const [Pagina, setPagina] = useState(0);
  const [CurrentPagina, setCurrentPagina] = useState(1);
  const [Id, setId] = useState("");
  const [Imagen, setImagen] = useState("");
  const [Status, setStatus] = useState("");
  const [Justificacion, setJustificacion] = useState(null);
  const [Fechas, setFechas] = useState("");

  const ENDPOINT = process.env.REACT_APP_BASE_URL;

  const [searchTerm, setSearchTerm] = useState("");

  const toggle = () => {
    setModal(!modal);
    setId("");
    setImagen("");
  };

  useEffect(() => {
    const date = new Date();
    const mes = date.getMonth() + 1;
    const mesActual = mes < 10 ? `0${mes}` : mes;

    RegistrosGetService({ IdUser, tokenFull, mesActual });
    return () => {};
  }, []);

  useEffect(() => {
    if (success == true) {
      const date = new Date();
      const mes = date.getMonth() + 1;
      const mesActual = mes < 10 ? `0${mes}` : mes;

      RegistrosGetService({ IdUser, tokenFull, mesActual });
    }
  }, [RegistrosGetService, success]);

  useEffect(() => {
    if (Ventas.length > 0) {
      setPagina(Math.round(Ventas.length / 10));
    }
  }, [Ventas]);

  const fecha = (fecha) => {
    let meses = [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DIC",
    ];
    let a = fecha.substr(0, 4);
    let m = fecha.substr(5, 2);
    let d = fecha.substr(8);
    return d + " / " + meses[m - 1] + " / " + a;
  };

  const verFactura = (id) => {
    window.open(ENDPOINT + "/images/facturas/" + id);
  };

  const toggleStatus = () => {
    setModalStatus(!ModalStatus);
    setId("");
    setStatus("");
  };

  const toggleDate = () => {
    setModalDates(!ModalDates);
    setId("");
    setFechas("");
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [SelectedMes, setSelectedMes] = React.useState("");
  const [checked, setChecked] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const BuscarPorMes = (mesActual) => {
    RegistrosGetService({ IdUser, tokenFull, mesActual });
  };

  // funcion formato moneda
  const formatoMexico = (valor) => {
    const valorFormateado = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 2,
    }).format(valor);
    return valorFormateado;
  };

  const handleChangeValidar = (event) => {
    const data = new FormData();
    data.append("registro_id", event.target.value);
    data.append("status", event.target.checked);

    RegistroUpdateValidarFacturaService(data, tokenFull);

    Swal.fire({
      html: "Espere por favor...",
      timer: 2000,
      confirmButtonColor: "#3b82f6",
      timerProgressBar: true,
    });
  };

  return (
    <>
      <Toaster />
      <ModalFactura
        modal={modal}
        toggle={toggle}
        Id={Id}
        Imagen={Imagen}
        setId={setId}
        setImagen={setImagen}
      />
      <ModalStatuss
        ModalStatus={ModalStatus}
        toggleStatus={toggleStatus}
        Id={Id}
        Status={Status}
        Justificacion={Justificacion}
        setId={setId}
        setStatus={setStatus}
        setJustificacion={setJustificacion}
        RegistrosGetService={RegistrosGetService}
      />
      <ModalDate
        ModalDates={ModalDates}
        toggleDate={toggleDate}
        Id={Id}
        Fechas={Fechas}
        setId={setId}
        setFechas={setFechas}
        RegistrosGetService={RegistrosGetService}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{ marginTop: 50 }}>
            <h2 className="titulo2">Facturas Registradas</h2>
            <div className="col-md-4">
              <label>Meses</label>
              <Select
                value={SelectedMes}
                onChange={(e) => {
                  setSelectedMes(e);
                  BuscarPorMes(e.value);
                }}
                options={Meses}
                placeholder={"Seleccionar Meses"}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <div
                  className="bg-success "
                  style={{ borderRadius: "50%", height: 10, width: 10 }}
                ></div>{" "}
                Facturas Validadas General
              </div>
              <h5 className="card-subtitle mb-2 text-muted">{ValidadoFull}</h5>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <div
                  className="bg-danger"
                  style={{ borderRadius: "50%", height: 10, width: 10 }}
                ></div>{" "}
                Facturas No Validadas General
              </div>
              <h5 className="card-subtitle mb-2 text-muted">
                {NoValidadoFull}
              </h5>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <div
                  className="bg-warning"
                  style={{ borderRadius: "50%", height: 10, width: 10 }}
                ></div>{" "}
                Facturas En Espera General
              </div>
              <h5 className="card-subtitle mb-2 text-muted">{EnEsperaFull}</h5>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <div
                  className="bg-success "
                  style={{ borderRadius: "50%", height: 10, width: 10 }}
                ></div>{" "}
                Facturas Validadas de Hoy
              </div>
              <h5 className="card-subtitle mb-2 text-muted">{ValidadoDay}</h5>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <div
                  className="bg-danger"
                  style={{ borderRadius: "50%", height: 10, width: 10 }}
                ></div>{" "}
                Facturas No Validadas de Hoy
              </div>
              <h5 className="card-subtitle mb-2 text-muted">{NoValidadoDay}</h5>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card text-center">
              <div className="card-body">
                <div
                  className="bg-warning"
                  style={{ borderRadius: "50%", height: 10, width: 10 }}
                ></div>{" "}
                Facturas En Espera de Hoy
              </div>
              <h5 className="card-subtitle mb-2 text-muted">{EnEsperaDay}</h5>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-4 mb-5">
            <label>Buscar</label>
            <input
              type="text"
              className="form-control"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="col-md-12">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        className="text-center"
                        style={{ background: "#ed7100" }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Ventas.filter((row) => {
                    if (searchTerm === "") {
                      return row;
                    } else {
                      const buscadorLower = searchTerm?.toLowerCase();
                      const titleLower = row.serie;
                      const estatus = row.status;

                      if (titleLower?.includes(buscadorLower)) {
                        return row;
                      }
                      if (estatus?.includes(buscadorLower)) {
                        return row;
                      }
                      
                     // if (titleLower?.includes(buscadorLower)) return row;
                    }
                  })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      let porciones = row.factura.split(".");
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell key={index} className="text-center">
                            {(page + 1) * rowsPerPage -
                              rowsPerPage +
                              (index + 1)}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {row.nombre} {row.apellido_paterno}{" "}
                            {row.apellido_materno}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {fecha(row.fecha_venta)}
                            <br />
                            <button
                              className="btn btn-info btn-sm"
                              onClick={() => {
                                setId(row.id);
                                setFechas(row.fecha_venta);
                                setModalDates(true);
                              }}
                            >
                              Actualizar Fecha
                            </button>
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {row.serie}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {row.modelo}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {row.puntos}
                          </TableCell>
                          <TableCell key={index}>
                            {porciones[1] === "pdf" ||
                            porciones[1] === "PDF" ? (
                              <span
                                className="btn btn-danger btn-sm text-white"
                                onClick={() => verFactura(row.factura)}
                              >
                                Ver Factura
                              </span>
                            ) : (
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => {
                                  setId(row.id);
                                  setImagen(row.factura);
                                  setModal(true);
                                }}
                              >
                                Ver Factura
                              </button>
                            )}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            <button
                              className="btn btn-secondary btn-sm"
                              onClick={() => {
                                setId(row.id);
                                setStatus(row.status);
                                setJustificacion(row.justificacion);
                                setModalStatus(true);
                              }}
                            >
                              Actualizar
                            </button>
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {(row.status == "validado" && (
                              <span
                                className="badge bg-success"
                                style={{
                                  width: 100,
                                  borderRadius: 10,
                                  textTransform: "capitalize",
                                }}
                              >
                                {row.status}
                              </span>
                            )) ||
                              (row.status == "no validado" && (
                                <span
                                  className="badge bg-danger"
                                  style={{
                                    width: 100,
                                    borderRadius: 10,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {row.status}
                                </span>
                              )) ||
                              (row.status == "en espera" && (
                                <span
                                  className="badge bg-warning"
                                  style={{
                                    width: 100,
                                    borderRadius: 10,
                                    color: "#000",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {row.status}
                                </span>
                              ))}
                          </TableCell>
                          <TableCell key={index} className="text-center">
                            {row.justificacion}
                          </TableCell>
                          {row.modelo === "FS 38" ||
                          row.modelo === "FS 55" ||
                          row.modelo === "FS 55 R" ||
                          row.modelo === "FS 120" ||
                          row.modelo === "FS 120" ||
                          row.modelo === "FS 120 R" ||
                          row.modelo === "FS 250" ||
                          row.modelo === "SR 420" ||
                          row.modelo === "SR 430" ||
                          row.modelo === "SR 450" ||
                          row.modelo === "MS 210" ||
                          row.modelo === "MS 250" ||
                          row.modelo === "MS 310" ? (
                            <TableCell key={index} className="text-center">
                              {row.participacion === "1" ||
                              row.participacion === 1 ? (
                                <span
                                  className="badge bg-success"
                                  style={{
                                    width: 100,
                                    borderRadius: 10,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Participo
                                </span>
                              ) : (
                                <span
                                  className="badge bg-danger"
                                  style={{
                                    width: 100,
                                    borderRadius: 10,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  No Participo
                                </span>
                              )}
                              <br />
                              {
                                row.porcentaje == null
                                ?
                                '0%'
                                :
                                row.porcentaje+'%'
                              }<br />
                              {formatoMexico(row.monto)}
                              <br />
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    sx={{ m: 1 }}
                                    value={row.id}
                                    checked={
                                      row.valido_factura === 1 ? true : false
                                    }
                                    onChange={handleChangeValidar}
                                  />
                                }
                                label="Factura validada"
                              />
                            </TableCell>
                          ) : (
                            <TableCell
                              key={index}
                              className="text-center"
                            ></TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "Todos", value: -1 },
                      ]}
                      colSpan={10}
                      count={Ventas.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "filas por página",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}
